var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "wrap--mypage"
  }, [_c('sub-visual', {
    attrs: {
      "visual": "마이페이지",
      "subTitle": "My page"
    }
  }), _c('v-container', [_c('mypage-navigation-row'), _c('router-view', {
    attrs: {
      "name": "question"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }