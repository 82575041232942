<template>
    <div v-if="question" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ question.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <!-- <v-col cols="auto">
                            <span>문의유형 : {{ question.type }}</span>
                        </v-col> -->
                        <v-col cols="auto">
                            <span>{{$t("common.date_created")}} : <span class="grey-7--text">{{ question.createdAt.toDate() }}</span></span>
                        </v-col>
                        <v-col cols="auto">
                            <span v-if="question.reply">상태 : <span class="primary--text">{{$t("common.answer_complete")}}</span></span>
                            <span v-else>상태 : <span class="grey-7--text">{{$t("common.unanswered")}}</span></span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr v-if="question.content">
                <td colspan="2" class="content_box grey-7--text">
                    <div v-html="question.content.replace(/\n/g,'<br>')"></div>
                </td>
            </tr>
            <tr v-if="question.reply" class="grey-f7">
                <td colspan="2" class="content_box grey-7--text">
                    <v-row no-gutters align="baseline">
                        <v-col cols="auto">
                            <div class="pr-8">
                                <v-avatar size="28" color="#9197a9" class="font-size-16 font-weight-bold line-height-1 white--text">A</v-avatar>
                            </div>
                        </v-col>
                        <v-col>
                            <div v-html="question.reply.replace(/\n/g,'<br>')"></div>
                        </v-col>
                    </v-row>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="grey-7" class="w-md-100 mw-md-100px">수정</v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">삭제</span></v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">답글</span></v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="list" large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">{{$t("common.list")}}</span></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    props: ["_id"],
    data(){
        return {
            question: null
        }
    },
	mounted() {
		this.init();
	},
	methods: {
		async init() {
            var { question } = await api.v1.center.questions.get({ _id: this.$props._id });
            this.question = question;
		},

        list(){
            this.$router.go(-1);
        },
	},
}
</script>
