var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.question.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("common.date_created")) + " : "), _c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.question.createdAt.toDate()))])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.question.reply ? _c('span', [_vm._v("상태 : "), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.$t("common.answer_complete")))])]) : _c('span', [_vm._v("상태 : "), _c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.$t("common.unanswered")))])])])], 1)], 1)]), _vm.question.content ? _c('tr', [_c('td', {
    staticClass: "content_box grey-7--text",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e(), _vm.question.reply ? _c('tr', {
    staticClass: "grey-f7"
  }, [_c('td', {
    staticClass: "content_box grey-7--text",
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "pr-8"
  }, [_c('v-avatar', {
    staticClass: "font-size-16 font-weight-bold line-height-1 white--text",
    attrs: {
      "size": "28",
      "color": "#9197a9"
    }
  }, [_vm._v("A")])], 1)]), _c('v-col', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])], 1)], 1)]) : _vm._e()]), _c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "board-view-navi"
  }, [_vm._m(0), _vm._m(1)]), _c('div', {
    staticClass: "mt-20 mt-md-30"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "color": "grey-7"
    }
  }, [_vm._v("수정")]), _c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v("삭제")])]), _c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v("답글")])])], 1), _c('div', {
    staticClass: "v-btn--group justify-end"
  }, [_c('v-btn', {
    staticClass: "w-md-100 mw-md-100px",
    attrs: {
      "large": "",
      "outlined": "",
      "color": "grey-d"
    },
    on: {
      "click": _vm.list
    }
  }, [_c('span', {
    staticClass: "grey-7--text"
  }, [_vm._v(_vm._s(_vm.$t("common.list")))])])], 1)])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("이전글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("이전 게시글 제목입니다.")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("다음글")]), _c('td', [_c('a', {
    attrs: {
      "href": ""
    }
  }, [_vm._v("다음 게시글 제목입니다.")])])]);

}]

export { render, staticRenderFns }