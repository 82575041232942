<template>
    <div class="board-container">
        <div v-show="false" class="board-search">
            <div class="board-search__box">
                <input v-model="filter.searchValue" class="board-search__input" type="text" name="" value="" />
                <button class="board-search__button" type="submit" name="button" @click="search"></button>
            </div>
            <div class="board-search__select">
                <select v-model="filter.type" class="board-search__selectbox" name="">
                    <option value="" disabled selected>문의항목을 선택해주세요.</option>
                    <option v-for="(type, index) in questionTypes" :key="index" :value="type">{{ type }}</option>
                </select>
            </div>
        </div>

        <table class="board-list">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:10%;" class="board-list__txt d-none d-lg-table-cell">{{ $t("common.number") }}</th>
                    <th style="width:60%;" class="board-list__tit">{{ $t("common.제목") }}</th>
                    <!-- <th style="width:15%;" class="board-list__txt">문의유형</th> -->
                    <th style="width:15%;" class="board-list__txt">등록일</th>
                    <th style="width:15%;" class="board-list__txt">상태</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(question, index) in questions" :key="question._id" @click="details(question)">
                    <td align="center" class="board-list__txt d-none d-lg-table-cell">
                        <span>{{ (page - 1) * limit + index + 1 }}</span>
                    </td>
                    <td align="center" class="board-list__tit">
                        <div class="ellip">
                            <span>{{ question.subject }}</span>
                        </div>
                    </td>
                    <!-- <td align="center" class="board-list__txt board-list__txt--first">
                        {{ question.type }}
                    </td> -->
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{ question.createdAt.toDate() }}
                    </td>
                    <td align="center" class="board-list__txt">
                        <span v-if="question.reply" class="font-weight-medium primary--text">{{ $t("common.answer_complete") }}</span>
                        <span v-else class="font-weight-medium grey-a--text">{{ $t("common.unanswered") }}</span>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"
                    ><span class="grey-7--text">{{ $t("common.list") }}</span></v-btn
                >
                <v-btn @click="write" large color="primary" class="w-md-100 mw-md-100px">{{ $t("common.writing") }}</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="write" large color="primary" class="w-md-100 mw-md-100px">{{ $t("common.writing") }}</v-btn>
            </div>
        </div>

        <pagination-component v-model="page" :count="pageCount" @input="search"></pagination-component>
    </div>
</template>

<script>
import api from "@/api";

import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: {
        PaginationComponent,
    },
    data() {
        return {
            filter: {
                searchKey: "subject",
                searchValue: this.$route.query.keyword || "",
                type: "",
            },

            page: 1,
            pageCount: 0,
            limit: 10,

            questions: [],
            questionTypes: ["회원정보 문의", "주문/결제 문의", "상품 문의", "배송 문의", "교환/반품 문의", "이벤트/혜택 문의", "서비스 개선 의견", "기타문의"],
        };
    },
    computed: {
        keyword() {
            return this.$route.query.keyword;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        keyword(value) {
            this.filter.searchValue = value;
            this.page = 1;
            this.search();
        },
    },
    methods: {
        init() {
            this.search();
            this.$nextTick(() => {
                // 문의내역 - 답변 토글
                (function() {
                    let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                        answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                    for (let i = 0; i < question.length; i++) question[i].addEventListener("click", toggleInquiry);

                    function toggleInquiry() {
                        let idx = this.getAttribute("data-idx"),
                            toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="' + idx + '"]');

                        if (toggle !== null) {
                            if (toggle.getAttribute("data-answer") === "opened") {
                                for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                            } else {
                                for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                                toggle.setAttribute("data-answer", "opened");
                            }
                        }
                    }
                })();
            });
        },

        write() {
            this.$router.push(`${this.$route.path}/create`);
        },

        details(question) {
            this.$router.push(`${this.$route.path}/${question._id}`);
        },

        async search() {
            var { summary, questions } = await api.v1.center.questions.gets({
                headers: {
                    skip: (this.page - 1) * this.limit,
                    limit: this.limit,
                },
                params: this.filter,
            });

            this.summary = summary;
            this.pageCount = Math.ceil(summary.totalCount / this.limit);
            this.questions = questions;
        },
    },
};
</script>
